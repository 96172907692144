<template>
    <div class="p-col-12 p-md-10 p-md-offset-1 p-align-center p-card p-shadow-3">
        <div class="p-grid p-align-start vertical-container" v-show="this.IsDataLoaded">
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-user p-mr-2" />{{ $t('bets.ticketHeaderAccountInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.ticketType') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ ticketContent.isSimpleTicket ? $t('bets.simpleTicket') : $t('bets.systemTicket') + ' : ' + ticketContent.system }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="!ticketContent.isSimpleTicket">
                    <div class="p-col-6 p-md-6 p-pt-0 p-pb-1">{{ $t('bets.noOfComb') }}</div>
                    <div class="p-col-6 p-md-6 p-pt-0 p-pb-1">{{ ticketContent.noOfCombinations }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.channel') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ getTicketChannel() }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.fromVirtual') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ ticketContent.fromWeb ? $t('general.yes') : $t('general.no') }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.placeDate') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ formatCSDateAndTime(ticketContent.ticketDate) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.processDate') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ ticketContent.validateDate != null ? formatCSDateAndTime(ticketContent.validateDate) : $t('bets.pendingTicket') }}</div>
                </div>
            </div>
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-wallet p-mr-2" />{{ $t('bets.ticketHeaderStakeInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.playedAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.playedAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.stakeAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.stakeAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.totalOdd') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ ticketContent.totalOdd }}</div>
                </div>
            </div>
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-dollar p-mr-2" />{{ $t('bets.ticketHeaderBetInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('general.status') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">
                        <Button :label="$t('bets.status.' + getTicketStatusName(ticketContent.status))" :class="'p-button-text  p-button-' + getTicketStatusColor(ticketContent.status) + ' p-pt-0 p-pb-0 p-ml-0 p-pl-0'" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status != 2">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.winAmount') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ notNullOrEmptyObject(ticketContent.winInfo) ? formatCurrency(ticketContent.winInfo.winAmount) : 0 }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status == 2">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.maxWinAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.maxWinAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status == 2">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.minWinAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.minWinAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.bonusAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.bonusAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.bonusAmount > 0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.bonusType') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ ticketContent.bonusDetails }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status == 2">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.cashOutAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ ticketContent.earlyCashoutActive ? formatCurrency(ticketContent.virtualWin) : $t('general.notAvailable') }}</div>
                </div>
            </div>
            <hr />
            <div class="p-col-12">
                <Panel>
                    <table>
                        <thead>
                            <tr class="p-text-bold">
                                <td>{{ $t('general.no') }}</td>
                                <td>{{ $t('bets.fixed') }}</td>
                                <td class="p-col-1 p-pl-0">
                                    {{ $t('sportsbets.offerNo') }}
                                </td>
                                <td class="p-col-2 p-pl-0">
                                    {{ $t('sportsbets.fixtureId') }}
                                </td>
                                <td class="p-col-2 p-pl-0">{{ $t('bets.event') }}</td>
                                <td class="p-col-2 p-pl-0">{{ $t('bets.startTime') }}</td>
                                <td class="p-col-2 p-pl-0">
                                    {{ $t('bets.market') }}
                                </td>
                                <td>{{ $t('bets.bet') }}</td>
                                <td>{{ $t('sportsbets.odd') }}</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="getTicketStatusColor(ticketLine.status)" v-for="(ticketLine, index) in ticketContent.ticketLines" :key="ticketContent.ticketSerial + 'line_' + index">
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ index + 1 }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">
                                    <i v-show="isFixed(index)" class="pi pi-check"></i>
                                </td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.eventNo }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.eventId }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.name }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ formatCSDateAndTime(ticketLine.date) }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.marketName }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.betName }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.betOdd }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ getTicketStatusName(ticketLine.status) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        </div>
        <div class="p-col-12 p-text-center" v-show="!this.IsDataLoaded && !this.showNoTicket">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
    </div>
</template>
<script>
import { BoTicketsApi } from '../../../service/BoTicketsApi';

export default {
    name: 'sportTicketDetails',
    emits: ['checkPayment'],
    props: {
        ticketSerial: {
            type: String,
        },
        forPay: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            BoTicketsApi: new BoTicketsApi(),
            ticketContent: [],
            IsDataLoaded: false,
            showNoTicket: false,
        };
    },
    beforeMount() {
        this.getTicketContent();
    },
    methods: {
        getTicketContent() {
            this.BoTicketsApi.getTicketContent(this.ticketSerial)
                .then((response) => {
                    this.ticketContent = response.data;
                    this.IsDataLoaded = true;
                    if (this.forPay) {
                        this.$emit('checkPayment', this.ticketContent.status);
                    }
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showNoTicket = true;
                    this.IsDataLoaded = false;
                });
        },
        isFixed(fixtureIndex) {
            return this.ticketContent.fixed.indexOf(fixtureIndex) >= 0;
        },
        getTicketChannel() {
            var response = '';
            if (this.ticketContent.onlineUserid != '') {
                response = this.$t('bets.channelOnline');
            } else {
                if (this.notNullOrEmptyField(this.ticketContent.cashierId)) {
                    response = this.$t('bets.channelCashier');
                } else {
                    response = this.$t('bets.channelTerminal');
                }
            }
            return response;
        },
    },
};
</script>

<style >
.success {
    background-color: #689f3885;
    border-bottom: 1px solid grey;
}
.warning {
    background-color: #fbc02d82;
    border-bottom: 1px solid grey;
}
.danger {
    background-color: #d32f2f85;
    border-bottom: 1px solid grey;
}
.info {
    background-color: #2196f387;
    border-bottom: 1px solid grey;
}
.help {
    background-color: #9c27b080;
    border-bottom: 1px solid grey;
}
.plain {
    background-color: #e4e4e4;
    border-bottom: 1px solid grey;
}
</style>
