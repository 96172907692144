<template>
    <div class="p-col-12 p-md-10 p-md-offset-1 p-align-center p-card p-shadow-3">
        <div class="p-grid p-align-start vertical-container p-pt-2" v-show="this.IsDataLoaded">
            <div class="p-col-12 p-md-10 p-md-offset-1" v-for="(market, index) in reportContent" :key="'market' + index">
                <div class="card p-grid">
                    <h6 v-if="market[0].specifier != ''" class="p-col-12">
                        {{ market[0].marketName + ': ' + market[0].specifier }}
                    </h6>
                    <h6 v-else class="p-col-12">
                        {{ market[0].marketName }}
                    </h6>
                    <div class="p-col-12 p-grid">
                        <div class="p-col-1"></div>
                        <div class="p-col-1">
                            {{ $t('bets.bet') }}
                        </div>
                        <div class="p-col-1">
                            {{ $t('bets.totalOdd') }}
                        </div>
                        <div class="p-col-1">
                            {{ $t('lotto.ticketsNo') }}
                        </div>
                        <div class="p-col-2">
                            {{ $t('bets.stakeAmount') }}
                        </div>
                        <div class="p-col-2">
                            {{ $t('riskManagement.ticketsPossibleWinSimple') }}
                        </div>
                        <div class="p-col-2">
                            {{ $t('riskManagement.ticketsPossibleWinSystem') }}
                        </div>
                        <div class="p-col-2">
                            {{ $t('bets.winAmount') }}
                        </div>
                    </div>
                    <div class="p-col-12 p-grid" v-for="(outcome, index) in market" :key="'outcome_' + outcome.marketId + '_' + outcome.specifier + '_' + outcome.outcomeId + '_' + index">
                        <div class="p-col-1">
                            <i class="pi pi-check p-mr-2" v-if="outcome.outcomeResult == true"></i>
                            <i class="pi pi-times p-mr-2" v-if="outcome.outcomeResult == false"></i>
                        </div>
                        <div class="p-col-1">
                            {{ outcome.outcomeName }}
                        </div>
                        <div class="p-col-1">{{ outcome.outcomeOdd }}</div>
                        <div class="p-col-1">{{ outcome.ticketsNo }}</div>
                        <div class="p-col-2">
                            {{ formatCurrency(outcome.ticketsStake) }}
                        </div>
                        <div class="p-col-2">
                            {{ formatCurrency(outcome.ticketsPossibleWinSimple) }}
                        </div>
                        <div class="p-col-2">
                            {{ formatCurrency(outcome.ticketsPossibleWinSystem) }}
                        </div>
                        <div class="p-col-2">
                            {{ formatCurrency(outcome.ticketsWin) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportReportsApi } from '../../../service/SportReportsApi';

export default {
    name: 'RiskFixture',
    props: {
        fixtureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            SportReportsApi: new SportReportsApi(),
            reportContent: [],
            IsDataLoaded: false,
        };
    },
    beforeMount() {
        this.SportReportsApi.riskFixtureReport(this.fixtureId)
            .then((response) => {
                this.reportContent = response.data;
                this.IsDataLoaded = true;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.IsDataLoaded = true;
            });
    },
};
</script>