<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Toast />
            <div class="card">
                <TabView>
                    <TabPanel :header="$t('riskManagement.riskReport')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                                    <label>{{ $t('sportsbets.startDate') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                                    <label>{{ $t('sportsbets.endDate') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <InputText type="number" step="0.01" style="width: 100%" v-model="ticketsStake" />
                                    <label>{{ $t('bets.stakeAmount') }}</label>
                                </span>
                            </div>
                            <div class="p-col-612 p-md-6 p-text-right"><Button type="button" :loading="isRiskReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showRiskReport()" /></div>
                        </div>
                        <div class="card" v-if="showRiskData">
                            <DataTable :rows="perPage" :paginator="true" :value="riskItems" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRowsRisk">
                                <Column>
                                    <template #body="slotProps">
                                        <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                    </template>
                                </Column>
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                                <Column field="fixtureName" :header="$t('bets.event')" :sortable="true"></Column>

                                <Column field="fixtureDate" :header="$t('bets.startTime')">
                                    <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.fixtureDate) }} </template></Column
                                >
                                <Column field="status" :header="$t('general.status')" :sortable="true">
                                    <template #body="slotProps">
                                        <Button :label="slotProps.data.status" :class="'p-button-text  p-button-' + getNameStatusColor(slotProps.data.status) + ' p-pt-0 p-pb-0 p-ml-0 p-pl-0'" />
                                    </template>
                                </Column>
                                <Column field="ticketsNo" :header="$t('lotto.ticketsNo')" :sortable="true"></Column>

                                <Column field="ticketsStake" :header="$t('bets.stakeAmount')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.ticketsStake) }} </template></Column
                                >
                                <Column field="ticketsPossibleWinSimple" :header="$t('riskManagement.ticketsPossibleWinSimple')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.ticketsPossibleWinSimple) }} </template></Column
                                >
                                <Column field="ticketsPossibleWinSystem" :header="$t('riskManagement.ticketsPossibleWinSystem')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.ticketsPossibleWinSystem) }} </template></Column
                                >
                                <Column field="ticketsWin" :header="$t('bets.winAmount')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.ticketsWin) }} </template></Column
                                >
                                <template #expansion="slotProps">
                                    <RiskFixture :fixtureId="slotProps.data.fixtureId"></RiskFixture>
                                </template>
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel :header="$t('riskManagement.exposureReport')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <InputText type="number" step="1" style="width: 100%" v-model="pendingLinesNo" />
                                    <label>{{ $t('riskManagement.pendingLinesNo') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                                    <label>{{ $t('dropdown.placeholder.shop') }}</label>
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <span class="p-input-icon-right">
                                    <InputText type="text" :placeholder="$t('search.byEvent')" v-model="searchTerm" @input="debounceSearch" />
                                    <i class="pi pi-search" />
                                </span>
                            </div>
                            <div class="p-col-612 p-md-6 p-text-right"><Button type="button" :loading="isExposureReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showExposureReport()" /></div>
                        </div>
                        <div class="card" v-if="showExposureData">
                            <DataTable :rows="perPage" :paginator="true" :value="filteredData" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRowsExposure">
                                <Column>
                                    <template #body="slotProps">
                                        <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                    </template>
                                </Column>
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="TicketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                                <Column field="ShopId" :header="$t('bets.shopName')">
                                    <template #body="{ data }">{{ shopsDictionary[data.ShopId] }}</template>
                                </Column>
                                <Column field="Date" :header="$t('bets.placeDate')">
                                    <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.Date) }} </template></Column
                                >
                                <Column field="Stake" :header="$t('bets.stakeAmount')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.Stake) }} </template></Column
                                >
                                <Column field="MinWin" :header="$t('bets.minWinAmount')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.MinWin) }} </template></Column
                                >
                                <Column field="MaxWin" :header="$t('bets.maxWinAmount')">
                                    <template #body="slotProps">{{ formatCurrency(slotProps.data.MaxWin) }} </template></Column
                                >
                                <Column field="Events" :header="$t('bets.events')">
                                    <template #body="slotProps"
                                        >{{
                                            slotProps.data.Events.map((elem) => {
                                                return elem.Name;
                                            }).join(',')
                                        }}
                                    </template></Column
                                >
                                <template #expansion="slotProps">
                                    <SportTicketDetails :ticketSerial="slotProps.data.TicketSerial"></SportTicketDetails>
                                </template>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import { SportReportsApi } from '../../service/SportReportsApi';
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
import SportTicketDetails from '../Bets/Components/SportTicketDetails.vue';
import RiskFixture from './Components/RiskFixture.vue';

export default {
    components: {
        SportTicketDetails,
        RiskFixture,
    },
    data() {
        return {
            riskItems: [],
            expandedRowsRisk: [],
            expandedRowsExposure: [],
            exposureItems: [],
            SportReportsApi: new SportReportsApi(),
            BoApi: new BoApi(),
            perPage: uiSettings.tableRows,
            showRiskData: false,
            showExposureData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            ticketsStake: 100,
            isRiskReportLoading: false,
            isExposureReportLoading: false,
            shopsDictionary: [],
            shops: [],
            shopsModel: { name: 'All', id: 'all' },
            pendingLinesNo: 1,
            term: '',
            debounce: null,
            searchTerm: '',
            debounceTime: 0,
        };
    },
    computed: {
        filteredData() {
            if (!this.term || this.term.length < 1) {
                return this.exposureItems;
            }
            let searchItem = this.term;
            return this.exposureItems.filter((e) => e.Events.some((subElement) => subElement.Name.toLowerCase().indexOf(searchItem) >= 0));
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.getShops();
        this.debounceTime = this.getSearchDebounceSeconds();
    },
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { name: 'All', id: 'all' };
            }
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.createShopsDictionary();
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        createShopsDictionary() {
            for (let i = 0; i < this.shops.length; i++) {
                this.shopsDictionary[this.shops[i].id] = this.shops[i].name;
            }
        },
        showRiskReport() {
            this.showRiskData = false;
            this.isRiskReportLoading = true;
            var requestObj = {
                StartDate: this.formatEnDateAndTime(this.startDateModel),
                EndDate: this.formatEnDateAndTime(this.endDateModel),
                TicketStake: this.ticketsStake,
            };
            this.SportReportsApi.riskMainReport(requestObj)
                .then((response) => {
                    this.riskItems = response.data;
                    this.isRiskReportLoading = false;
                    this.showRiskData = true;
                })
                .catch((error) => {
                    this.isRiskReportLoading = false;
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showExposureReport() {
            this.showExposureData = false;
            this.isExposureReportLoading = true;
            var requestObj = {
                LinesNo: this.pendingLinesNo,
                ShopId: this.shopsModel.id,
            };
            this.SportReportsApi.exposureReport(requestObj)
                .then((response) => {
                    this.exposureItems = response.data;
                    this.showExposureData = true;
                    this.isExposureReportLoading = false;
                })
                .catch((error) => {
                    this.isExposureReportLoading = false;
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        getNameStatusColor(name) {
            switch (name) {
                case 'ACTIVE': {
                    return 'success';
                }
                case 'SETTLED': {
                    return 'warning';
                }
            }
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
    },
};
</script>
