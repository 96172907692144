import axios from 'axios'
import settings from '../settings/apis.js'

class SportReportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].sportsApiUrl + "/reports/",
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    riskMainReport(request) {
        return this.Api.post('riskMainReport', request);
    }
    exposureReport(request) {
      return this.Api.post('exposureReport', request);
    }
    riskFixtureReport(fixtureId) {
      return this.Api.get('riskFixtureReport/' + fixtureId);
    }
   
   
}

export {SportReportsApi}
